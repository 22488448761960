import React, { useEffect, useState } from "react"

import { Column, Row, Title } from "styled_components"
import { ActivityCard } from "components"
import { Order } from "types/order.type"
import { TicketOption } from "./style"
import Divider from "@mui/material/Divider"
import { Discount } from "backend/api/discounts"
import { getBookingsPrice } from "backend/api/bookings"
import { Activity } from "backend/api/activities"
import { ActivityTypeEnum } from "helpers/constants"
import { isNumber } from "lodash"

interface Props {
  order: Order
  activity: Activity
  discounts: Discount[]
}

const BookingSummary = ({ order, discounts, activity }: Props) => {
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number | undefined>()

  useEffect(() => {
    calculatePriceWithDiscount()
  }, [discounts])

  const calculatePriceWithDiscount = async () => {
    if (discounts && discounts.length > 0) {
      let priceWithDiscount = 0
      for (const activity of order.selected_activities) {
        const discount = discounts.find((discount) => discount.activityId === activity.id)
        const activityBookings = order.bookings.filter(
          (booking) => booking.activity_id === activity.id,
        )
        const priceForOrder = await getBookingsPrice(activity.id, activityBookings, discount?.code)
        priceWithDiscount += priceForOrder.price
      }
      setTotalPriceWithDiscount(priceWithDiscount)
    }
  }

  return (
    <Column width="100%">
      {order.selected_activities.map((activity, index) => {
        const isLastIndex = index === order.selected_activities.length - 1
        const activityBookings = order.bookings.filter(
          (booking) => booking.activity_id === activity.id,
        )
        const bookingDate = order.bookings.find((booking) => booking.activity_id === activity.id)
        const totalPrice =
          activity.informations.bookingSettings.type === ActivityTypeEnum.GROUP
            ? order.bookings.reduce((acc, booking) => acc + booking.price, 0)
            : order.bookings.reduce((acc, booking) => acc + booking.price * booking.numberOfUnit, 0)
        const discount: Discount | undefined = discounts.find(
          (discount) => discount.activityId === activity.id,
        )
        return (
          <>
            <Row width="100%" space mobile="flex-direction: column;">
              <Row width="calc(100% - 400px)" mobile={"width: 100%"}>
                <ActivityCard activity={activity} bookingDate={bookingDate} />
              </Row>
              <Column width="350px" mobile={"width: 100%"}>
                {activityBookings.map((booking, index) => {
                  return (
                    <>
                      <TicketOption key={index}>
                        <Title bold>
                          {activity.informations.name} - {booking.priceName}
                        </Title>
                        <Row width="100%" margin="10px 0 0 0" space>
                          <Title>{parseFloat(booking.price.toString()).toFixed(2)}€</Title>
                          <Title>x{booking.numberOfUnit}</Title>
                        </Row>
                      </TicketOption>
                      <Divider style={{ marginBottom: "20px", width: "100%" }} />
                    </>
                  )
                })}
                <Title mobile="font-size: 18px;" size="26px">
                  Total :{" "}
                  {isNumber(totalPriceWithDiscount) ? (
                    <>
                      <span style={{ textDecorationLine: "line-through", margin: "0 10px 0 10px" }}>
                        {totalPrice.toFixed(2)}€
                      </span>

                      <span>{totalPriceWithDiscount.toFixed(2)}€</span>
                    </>
                  ) : (
                    <span style={{ marginLeft: "10px" }}> {totalPrice.toFixed(2)}€</span>
                  )}
                </Title>
              </Column>
            </Row>
            {!isLastIndex && <Divider style={{ margin: "40px 0 50px 0", width: "100%" }} />}
          </>
        )
      })}
    </Column>
  )
}

export default BookingSummary
