import React, { useState } from "react"
import dayjs from "dayjs"
import styled from "styled-components"

import { Button, Text } from "styled_components"
import { postInitPayment, postConfirmPayment } from "backend/api/payment"
import {
  BookingItem,
  confirmPaymentWithoutStripeInCaseNoPrice,
  postBooking,
} from "backend/api/bookings"
import { BookingSourceEnum, BookingStatusEnum } from "helpers/constants"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { FieldValues, UseFormTrigger } from "react-hook-form"
import { getQueryParams } from "helpers/helpers"
import Loading from "components/general/Loading"
import ErrorModal from "./ErrorModal"
import { useTranslation } from "react-i18next"

const CARD_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(63, 63, 63)",
      width: "100%",
      color: "rgb(63, 63, 63)",
      fontWeight: 600,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "rgb(63, 63, 63)" },
      "::placeholder": { color: "rgb(63, 63, 63)" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
}

interface Props {
  visitorInfos: {
    fullName: string
    email: string
    phoneNumber: string
  }
  bookings: BookingItem[]
  triggerFormValidation: UseFormTrigger<FieldValues>
  t: any // Assuming t is a function for translations
  discountCode?: string
  handleBack: () => void
  paymentStatus: "error" | "success" | undefined
  setPaymentStatus: (status: "error" | "success" | undefined) => void
  isPaymentRequired?: boolean
}

const StripePayment = ({
  visitorInfos,
  bookings,
  t,
  triggerFormValidation,
  discountCode,
  handleBack,
  paymentStatus,
  setPaymentStatus,
  isPaymentRequired = true,
}: Props) => {
  const { i18n } = useTranslation()
  const urlParams = getQueryParams()
  const CLIENT_ID = urlParams.get("clientId") || ""
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initiedPayment, setInitiedPayment] = useState({
    stripeClientSecret: "",
    paymentId: 0,
    paymentMethodId: "",
  })
  const [createdBookingIds, setCreatedBookingIds] = useState<number[]>([])
  const [bookingError, setBookingError] = useState("")

  const createBookings = async (): Promise<number[]> => {
    const bookingIds: number[] = []
    // @ts-ignore
    const uniqueActivityIds: number[] = [...new Set(bookings.map((item) => item.activity_id))]
    const formatBookings = bookings.map((item) => {
      return {
        ...item,
        status: BookingStatusEnum.TO_PAY,
        from: dayjs(item.from).format("YYYY-MM-DD HH:mm"),
        to: dayjs(item.to).format("YYYY-MM-DD HH:mm"),
      }
    })
    for (const activityId of uniqueActivityIds) {
      try {
        const formatBookingsForActivityId = formatBookings.filter(
          (item) => item.activity_id === activityId,
        )
        const createdBookings = await postBooking(activityId, {
          visitorInfos: visitorInfos,
          bookings: formatBookingsForActivityId,
          source: BookingSourceEnum.WIDGET,
          discountCode: discountCode || "",
        })
        if (!createdBookings[0].id) {
          // @ts-ignore
          throw new Error(createdBookings.error)
        } else {
          const createdBookingIdsTmp = createdBookings.map((item) => item.id)
          // @ts-ignore
          const createdBookingIds: number[] = createdBookingIdsTmp.filter((item) => Boolean(item))
          if (createdBookingIds.length > 0) bookingIds.push(...createdBookingIds)
        }
      } catch (error: any) {
        console.log(error)
        setBookingError(error.response.data.error)
        return []
      }
    }
    return bookingIds.filter((id) => id !== undefined) as number[]
  }

  const confirmPayment = async (paymentInit: {
    stripeClientSecret: string
    paymentId: number
    paymentMethodId: string
  }) => {
    const { stripeClientSecret, paymentId, paymentMethodId } = paymentInit

    if (!stripe || !elements) {
      console.log("Stripe has not loaded yet.")
      return
    }

    const cardElement = elements.getElement(CardElement)
    if (!cardElement) {
      console.log("Card element not found.")
      return
    }

    const result = await stripe.confirmCardPayment(stripeClientSecret, {
      payment_method: paymentMethodId,
    })

    if (result.error) {
      console.error("Error:", result.error)
      setPaymentStatus("error")
      return
    } else if (result.paymentIntent.status === "succeeded") {
      await postConfirmPayment({
        paymentId,
        clientId: Number(CLIENT_ID),
        lang: i18n.language.includes("en") ? "en" : "fr",
      })
      setPaymentStatus("success")
    } else {
      setPaymentStatus("error")
    }

    return
  }

  const handlePaymentSubmission = async (event: React.FormEvent) => {
    if ((!stripe || !elements) && isPaymentRequired) {
      console.log("Stripe has not loaded yet.")
      return
    }
    const cardElement = elements?.getElement(CardElement)
    if (!cardElement && isPaymentRequired) {
      console.log("Card element not found.")
      return
    }

    // ALREADY INITED PAYMENT //
    if (initiedPayment.stripeClientSecret) {
      await confirmPayment(initiedPayment)
      return
    }
    // ALREADY INITED PAYMENT //

    // NEW PAYEMENT INITIALISATION //

    const bookingIds = createdBookingIds.length ? createdBookingIds : await createBookings()
    if (bookingError || !bookingIds.length) {
      setIsLoading(false)
      return
    }

    if (!createdBookingIds.length) {
      if (!isPaymentRequired) {
        await confirmPaymentWithoutStripeInCaseNoPrice(bookingIds)
        setPaymentStatus("success")
        return
      }
      setCreatedBookingIds(bookingIds)
    }
    //@ts-ignore
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: visitorInfos.fullName,
        email: visitorInfos.email,
        phone: visitorInfos.phoneNumber,
      },
    })

    if (error) {
      console.error("Error:", error)
      setPaymentStatus("error")
      return
    }
    const paymentInit = await postInitPayment({
      clientId: parseInt(CLIENT_ID),
      bookingIds: bookingIds,
      currency: "EUR",
      paymentMethodId: paymentMethod.id,
    })

    setInitiedPayment(paymentInit)
    await confirmPayment(paymentInit)
    return
  }

  const handleClickPay = async (event: React.FormEvent) => {
    const isFormValidate = await triggerFormValidation()

    if (isFormValidate) {
      setIsLoading(true)
      await handlePaymentSubmission(event)
      setIsLoading(false)
    }
  }

  return (
    <>
      {bookingError && <ErrorModal error={bookingError} handleBack={handleBack} />}
      {isLoading && <Loading />}
      {isPaymentRequired && (
        <InputPaymentContainer>
          {/* @ts-ignore */}
          <CardElement options={CARD_OPTIONS} />
        </InputPaymentContainer>
      )}
      {paymentStatus && (
        <Text margin="10px 0 0 0" style={{ color: "red" }}>
          {t("error.paymentProblem")}
        </Text>
      )}
      <Button
        type="submit"
        width="100%"
        margin="20px 0 0 0"
        onClick={handleClickPay}
        disabled={!useStripe()}
      >
        {isPaymentRequired ? t("pay") : t("book")}
      </Button>
    </>
  )
}

export default StripePayment

const InputPaymentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
  padding: 0;
  border-style: none;
  background-color: rgb(255, 255, 255);
  border: rgb(85, 85, 85) 1px solid;
  color: rgb(63, 63, 63) !important;
  will-change: opacity, transform;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #cccccc;
  border-radius: 4px;
  height: 50px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  font-family: "Sen", sans-serif;
  text-align: center;
  font-size: 18px;
  width: calc(100% - 5%);
`
