import { Activity } from "../backend/api/activities"

export const theme = {
  color: {
    primary: "#064643",
    grey: "#555555",
  },
  mediaQueries: {
    mobile: "@media (max-width: 865px)",
  },
}

export const activityCategories = {
  ARCADE: "arcade",
  BOWLING: "bowling",
  LASER_GAME: "laseGame",
  PAINT_BALL: "paintBall",
  ESCAPE_GAME: "escapeGame",
}

export const activitySlotTypes = {
  "30_MINUTES": "30 Minutes",
  "60_MINUTES": "60 Minutes",
  "90_MINUTES": "90 Minutes",
}

// Enums
export enum BookingStatusEnum {
  TO_PAY = "TO_PAY",
  BOOKED = "BOOKED",
  PENDING = "PENDING",
  CANCELED = "CANCELED",
}
export enum BookingSourceEnum {
  WIDGET = "WIDGET",
  HOPLEISURE = "HOPLEISURE",
}

export enum DiscountTypesEnum {
  PERCENTAGE = "PERCENTAGE",
  SUBSTRACTION = "SUBSTRACTION",
}

export enum ActivityPricesStatusEnum {
  ENABLED = "ENABLED",
  DELETED = "DELETED",
  DISABLED = "DISABLED",
}

export enum ActivityPricesTypeEnum {
  DEFAULT = "DEFAULT",
  SENIOR = "SENIOR",
  CHILD = "CHILD",
  GROUP = "GROUP",
}

export enum ActivitySpecialSlotTypesEnum {
  MARK_AS_FULL = "MARK_AS_FULL",
  UNAVAILABLE = "UNAVAILABLE",
}

export enum ActivityTypeEnum {
  GROUP = "GROUP",
  INDIVIDUAL = "INDIVIDUAL",
}

export const emptyActivity: any = {
  informations: {
    description: null,
    maxCapacity: 1,
    slotDuration: 0,
    images: [],
    email: null,
    name: null,
    address: null,
    phone: null,
    cancelation: null,
    category: "ARCADE",
    isEnabled: true,
  },
  hours: {
    hours: {
      "0": {
        slots: [],
      },
      "1": {
        slots: [],
      },
      "2": {
        slots: [],
      },
      "3": {
        slots: [],
      },
      "4": {
        slots: [],
      },
      "5": {
        slots: [],
      },
      "6": {
        slots: [],
      },
    },
  },
  prices: [],
  channels: [],
}

export const listOfPositiveNumber = (number: number) =>
  Array.from(Array(number).keys()).filter((item) => item !== 0)
