import React from "react"
import { useTranslation } from "react-i18next"
import { Column, Text, Title } from "styled_components"
import successImg from "images/success_payment.png"

interface Props {
  visitorInfos: {
    fullName: string
    email: string
    phoneNumber: string
  }
}

const PaymentSuccessfull = ({ visitorInfos }: Props) => {
  const { t } = useTranslation("bookingPayment")

  return (
    <Column alignCenter>
      <img src={successImg} style={{ width: "300px", maxWidth: "90vw", marginBottom: "35px" }} />
      <Title>{t("paymentSuccessfull.title", { name: visitorInfos.fullName })}</Title>
      <Text margin="15px 0 0 0">
        {t("paymentSuccessfull.description", { email: visitorInfos.email })}
      </Text>
    </Column>
  )
}

export default PaymentSuccessfull
