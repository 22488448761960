import { Column } from "styled_components";
import styled from "styled-components";
import { theme } from "helpers/constants";

export const DatePickerContainer = styled(Column)`
  width: 45%;
  
  ${theme.mediaQueries.mobile} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`