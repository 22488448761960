import { z } from "zod";
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Importez la locale française

dayjs.locale('fr'); // Utilisez la locale française

export const numberablePositiveString = z.string().refine(
  (value: string) => {
    return value !== '' && Number.isFinite(Number(value)) && Number(value) >= 0;
  },
  {
    message: 'Value must be a positive number',
  }
);

const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/;
export const dateableString = z
  .string()
  .refine((value: string) => yyyymmddRegex.test(value), {
    message: 'field must be in the YYYY-MM-DD format',
});

const yyyymmddhhmmRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
export const dateableWithTimeString = z
  .string()
  .refine((value: string) => yyyymmddhhmmRegex.test(value), {
    message: 'field must be in the YYYY-MM-DD HH:mm format',
  });


export function getAuthorizedConfig(method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE') {
  const accessToken = localStorage.getItem("accessToken")
  const config = {
      method: method,
      headers: { 
          'Authorization': `Bearer ${accessToken}`
      },
      url: '',
      data: {}
  }
  return config;
}

export const pick = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));

export const omit = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));

export interface Message {
  type: 'success' | 'error',
  message: string,
}

export function getQueryParams(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

export function formatDate(isoDate: string) {
  return dayjs(isoDate).format('D MMMM YYYY');
}