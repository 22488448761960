import { theme } from "helpers/constants"
import styled from "styled-components"

export const Title = styled.h3<{
  bold?: boolean
  grey?: boolean
  size?: string | number
  margin?: string
  underline?: boolean
  pointer?: boolean
  width?: string
  mobile?: string
}>`
  font-size: ${(props) => (props.size ? props.size : "18px")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${(props) => (props.grey ? "grey" : "black")};
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
  width: ${(props) => (props.width ? props.width : "auto")};
  ${theme.mediaQueries.mobile} {
    ${(props) => (props.mobile ? props.mobile : "")}
  }
`

export const Text = styled.p<{
  bold?: boolean
  grey?: boolean
  white?: boolean
  size?: string | number
  margin?: string
  underline?: boolean
  pointer?: boolean
}>`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${(props) => (props.grey ? theme.color.grey : props.white ? "white" : "black")};
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
`

export const Link = styled.a<{
  bold?: boolean
  grey?: boolean
  size?: string | number
  color?: string
  margin?: string
}>`
  font-size: ${(props) => (props.size ? props.size : "14px")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${(props) => (props.color ? props.color : theme.color.primary)};
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  text-decoration: underline;
  cursor: pointer;
`

export const Row = styled.div<{
  start?: boolean
  end?: boolean
  center?: boolean
  space?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  alignCenter?: boolean
  alignSpace?: boolean
  margin?: string
  width?: string
  mobile?: string
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.start
      ? "flex-start"
      : props.end
        ? "flex-end"
        : props.center
          ? "center"
          : props.space
            ? "space-between"
            : "flex-start"};
  align-items: ${(props) =>
    props.alignStart
      ? "flex-start"
      : props.alignEnd
        ? "flex-end"
        : props.alignCenter
          ? "center"
          : props.alignSpace
            ? "space-between"
            : "flex-start"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: ${(props) => (props.width ? props.width : "auto")};

  ${theme.mediaQueries.mobile} {
    ${(props) => (props.mobile ? props.mobile : "")}
  }
`

export const Column = styled.div<{
  start?: boolean
  end?: boolean
  center?: boolean
  space?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  alignCenter?: boolean
  alignSpace?: boolean
  margin?: string
  width?: string
  mobile?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.start
      ? "flex-start"
      : props.end
        ? "flex-end"
        : props.center
          ? "center"
          : props.space
            ? "space-between"
            : "flex-start"};
  align-items: ${(props) =>
    props.alignStart
      ? "flex-start"
      : props.alignEnd
        ? "flex-end"
        : props.alignCenter
          ? "center"
          : props.alignSpace
            ? "space-between"
            : "flex-start"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: ${(props) => (props.width ? props.width : "auto")};

  ${theme.mediaQueries.mobile} {
    ${(props) => (props.mobile ? props.mobile : "")}
  }
`

export const Button = styled.button<{ secondary?: boolean; width?: string; margin?: string }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; // Espace entre l'icône et le texte
  position: relative;
  width: auto;
  height: 46px;
  padding: 20px 20px;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  ${(props) => (props.secondary ? `color: ${theme.color.primary};` : "color: #ffffff;")}
  ${(props) => (props.secondary ? `border: 1px solid ${theme.color.primary};` : "border: none;")}
${(props) => (props.secondary ? "background: #ffffff;" : `background: ${theme.color.primary};`)}
width: ${(props) => (props.width ? props.width : "auto")};
`
