import styled from "styled-components"

export const WidgetContainer = styled.div`
  padding: 20px 10px;
  overflow-y: scroll;
  position: relative;
  border: #829695;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 #bdbdbd;
`
