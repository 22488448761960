import React from 'react';
import ReactDOM from 'react-dom/client';
import './helpers/trads/i18n';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Router from './Router';
import { QueryClientProvider, QueryClient } from 'react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'


const theme = createTheme({
  palette: {
    primary:{
      main: "#054643"
    },
    secondary:{
      main: "#4FD1C5",
      contrastText: '#fff',
    },
    info: {
      main: "#2CA58D",
      contrastText: '#fff',
    },
    success: {
      main: "#2CA58D",
      contrastText: '#fff',
      light: '#E0E1E2',
    }
  },
  typography: {
    fontFamily: 'Nunito',
    button: {
      textTransform: 'none',
      fontSize: 16,
    },
    fontSize: 16,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#054643",
          color: "white",
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '8px',
        }
      }
    },MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.3)",
          border: "1px solid #c3c3c3",
          borderRadius: "6px",
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "white",
          padding: "20px",
          borderRadius: "8px",
        },
      },
    },
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router />
          </LocalizationProvider>
        </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
