import { theme } from "helpers/constants"
import styled from "styled-components"

export const TicketOptionsContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  width: 24%;

  ${theme.mediaQueries.mobile} {
    max-width: 100%;
    width: 100%;
  }
`

export const TicketOption = styled.div`
  margin-bottom: 20px;
`
