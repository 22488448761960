import React, { useRef, useState } from "react"
import { useQuery } from "react-query"
import { getActivities } from "backend/api/activities"
import { WidgetContainer } from "./style"
import { Order } from "types/order.type"
import DateTicketPicker from "widget/DateTicketPicker"
import ActivityList from "widget/ActivitiyList"
import Payment from "widget/Payment"
import { getQueryParams } from "helpers/helpers"
import LanguageSelector from "components/general/LanguageSelector"
import { stripeConnectVerification } from "backend/api/stripeConnect"
import Skeletons from "components/general/Skeletons"
import { useTranslation } from "react-i18next"

export type Step = "activityList" | "dateTicketPicker" | "payment"

const Widget = () => {
  const { t } = useTranslation("general")
  const urlParams = getQueryParams()
  const CLIENT_ID = urlParams.get("clientId") || ""
  const [currentStep, setCurrentStep] = useState<Step>("activityList")
  const [order, setOrder] = useState<Order>({
    selected_activities: [],
    bookings: [],
  })

  const widgetRef = useRef(null) 

  const handleScrollToWidget = () => {
    const widgetElement = widgetRef.current
    if (widgetElement) {
		// @ts-ignore
      const widgetTop = widgetElement.getBoundingClientRect().top
      if (widgetTop > 50) {  // Scroll if y postition > 50x diff 
		// @ts-ignore
        widgetElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }

  const { data: activities, isLoading: isActivitiesLoading } = useQuery({
    queryKey: [`get-all-actitivities-${CLIENT_ID}`],
    queryFn: () => getActivities(CLIENT_ID),
    staleTime: 60000,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const openedActivities = activities?.filter(activity => {
    const widgetChannel = activity.channels.find(channel => channel.name === "widget")
    return widgetChannel?.status === "OPENED"
  })

  const { data: stripeVerification, isLoading: isStripeVerificationLoading } = useQuery({
    queryKey: [`strip-verification-${CLIENT_ID}`],
    queryFn: () => stripeConnectVerification(CLIENT_ID),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {},
  })

  const handleChangeStep = (step: Step) => {
    const handleBackFromPayment = () => {
      setOrder((prevOrder) => {
        return { ...prevOrder, bookings: [] }
      })
    }
    setCurrentStep(step)
    if (step === "dateTicketPicker" || step === "activityList") {
      handleBackFromPayment()
    }
  }

  if (isActivitiesLoading || isStripeVerificationLoading) {
    return (
      <WidgetContainer ref={widgetRef}>
        <Skeletons />
      </WidgetContainer>
    )
  }

  if (!stripeVerification?.accountCompleted || !openedActivities) {
    return (
      <WidgetContainer ref={widgetRef}>
        <h1>{t("badAccountConfig")}</h1>
      </WidgetContainer>
    )
  }

  return (
    <WidgetContainer id="hopleisure-widget" ref={widgetRef} onClick={handleScrollToWidget}>
      <LanguageSelector />
      {currentStep === "activityList" && (
        <ActivityList
          activities={openedActivities}
          order={order}
          setOrder={setOrder}
          handleChangeStep={handleChangeStep}
        />
      )}
      {currentStep === "dateTicketPicker" && order.selected_activities?.length > 0 && (
        <DateTicketPicker
          selectedActivies={order.selected_activities}
          handleChangeStep={handleChangeStep}
          order={order}
          setOrder={setOrder}
        />
      )}
      {currentStep === "payment" && <Payment order={order} activity={order.selected_activities[0]} stripeAccountId={stripeVerification.accountId} handleChangeStep={handleChangeStep} />}
    </WidgetContainer>
  )
}

export default Widget
