import styled from "styled-components"



export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin-top: 20px;
  max-width: 95vw;
`
