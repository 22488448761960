const general: any = {
  en: {
    badAccountConfig:
      "Please check your HoPleisure account configuration, your clientId passed as a parameter, as well as your Stripe account configuration on HoPleisure.",
  },
  fr: {
    badAccountConfig:
      "Veuillez vérifier votre configuration de compte HoPleisure, votre clientId passé en paramètre, ainsi que la configuration de votre compte Stripe sur HoPleisure.",
  },
}

export default general
