const bookingPayment: any = {
  en: {
    pay: "Pay and book",
    book: "Book",
    previous: "Previous",
    bookingInProgress: "Booking in progress",
    ticketSelection: "Ticket selection",
    payOneCard: "Pay with one card",
    payManyCards: "Pay with many cards",
    activatePromoCode: "Activate",
    promoCode: "Promo code",
    error: {
      paymentProblem: "Payment failed",
      promoCodeNotAvailable:
        "The quantity of tickets selected is greater than the quantity of tickets available for this promo code",
    },
    form: {
      name: {
        label: "Your firstname and lastname",
        error: "Complete name is required",
      },
      email: {
        label: "Your email address",
        error: "Email address is required",
      },
      phoneNumber: {
        label: "Phone number",
        error: "Phone number is required",
      },
    },
  },
  fr: {
    pay: "Payer et réserver",
    book: "Réserver",
    previous: "Précédent",
    bookingInProgress: "Réservation en cours",
    ticketSelection: "Sélection de votre billet",
    payOneCard: "Paiement une carte",
    payManyCards: "Paiement plusieurs cartes",
    activatePromoCode: "Activer",
    promoCode: "Code promo",
    paymentSuccessfull: {
      title: "Merci {{name}}, réservation finalisée avec succès !",
      description:
        "Vous recevrez rapidement une confirmation de réservation par email à : {{email}}",
    },
    error: {
      paymentProblem: "Le paiement a échoué",
      promoCodeNotAvailable:
        "La quantité de billets sélectionnée est supérieure à la quantité de billets disponibles pour ce code promo",
    },
    form: {
      name: {
        label: "Votre nom et prénom",
        error: "Nom et prénom sont requis",
      },
      email: {
        label: "Votre adresse email",
        error: "Adresse email est requise",
      },
      phoneNumber: {
        label: "Numéro de téléphone",
        error: "Numéro de téléphone est requis",
      },
    },
  },
}

export default bookingPayment
