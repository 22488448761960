import React from "react"
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined"
import {
  ActivityCardContainer,
  ImageContainer,
  ContentContainer,
  HeartIcon,
  HeartIconSelected,
  ActivityCardButtonContainer,
} from "./style" // Assurez-vous d'importer les composants stylisés
import { Activity } from "backend/api/activities"
import { Title, Text, Row, Button, Column, Link } from "styled_components"
import { Step } from "widget"
import dayjs from "dayjs"
import { formatDate } from "helpers/helpers"
import { useTranslation } from "react-i18next"
import CancellationPolicyModal from "./CancellationPolicyModal"
import styled from "styled-components"

interface BookingDate {
  from: string
  to: string
}

interface Props {
  activity: Activity
  handleSelect?: (activity: Activity, isSelected: boolean) => void
  isSelected?: boolean
  handleChangeStep?: (step: Step) => void
  bookingDate?: BookingDate
}

const ActivityCard = ({
  activity,
  handleSelect,
  isSelected,
  handleChangeStep,
  bookingDate,
}: Props) => {
  const { t } = useTranslation("activityList")
  const [isCancellationPolicyModalOpen, setIsCancellationPolicyModalOpen] = React.useState(false)

  const imageUrl =
    activity.informations.images.length > 0 ? activity.informations.images[0].url : ""

  const isActivityFinish =
    activity.hours.dates?.to && new Date(activity.hours.dates?.to || "") < new Date()

  const isResumeDisplay = bookingDate !== undefined

  const isSelectableActivityCard =
    isSelected !== undefined &&
    handleSelect !== undefined &&
    handleChangeStep !== undefined &&
    !isActivityFinish

  const isTemporaryActivity = activity.hours.dates?.from && activity.hours.dates?.to

  if (isActivityFinish) {
    return <></>
  }

  return (
    <ActivityCardContainer>
      {isCancellationPolicyModalOpen && (
        <CancellationPolicyModal
          isOpen={isCancellationPolicyModalOpen}
          handleClose={() => setIsCancellationPolicyModalOpen(false)}
          cancellationPolicy={activity.informations.cancelation}
        />
      )}
      <ImageContainer style={{ backgroundImage: `url(${imageUrl})` }} />
      {isSelectableActivityCard && (
        <ActivityCardButtonContainer>
          <Button secondary={!isSelected} onClick={() => handleSelect(activity, isSelected)}>
            {isSelected ? <HeartIconSelected /> : <HeartIcon />}
            {isSelected ? t("selected") : t("select")}
          </Button>
          {isSelected && (
            <Link onClick={() => handleChangeStep("dateTicketPicker")} margin="10px 0 0 0">
              {t("validateReservation")}
            </Link>
          )}
        </ActivityCardButtonContainer>
      )}
      {/*
      isActivityOutOfStock && (
        <ActivityCardButtonContainer>
          <Title mobile="margin: 0 0 30px 0;" grey>
            {t("outOfStock")}
          </Title>
        </ActivityCardButtonContainer>
      )
    */}
      <ContentContainer>
        <Row width="100%" space mobile={"flex-direction: column;"}>
          <Title margin="0 0 20px 0" bold size={18}>
            {activity.informations.name}
          </Title>
        </Row>
        <Text margin="0 0 15px 0">
          <FmdGoodOutlinedIcon style={{ color: "black", fontSize: "16px", marginRight: "4px" }} />{" "}
          {activity.informations.address}
        </Text>
        {!isResumeDisplay ? (
          <>
            {isTemporaryActivity ? (
              <Text grey margin="0 0 20px 0">
                {t("from")} {formatDate(activity.hours.dates?.from || "")} {t("to")}{" "}
                {formatDate(activity.hours.dates?.to || "")}
              </Text>
            ) : (
              <Text grey margin="0 0 20px 0">
                {t("noTemporaryActivity")}
              </Text>
            )}
            <TextDescription style={{ whiteSpace: "pre-wrap" }}>
              {activity.informations.description}
            </TextDescription>
          </>
        ) : (
          <Column space margin="0 0 20px 0" width="100%">
            <Title style={{ whiteSpace: "nowrap" }} bold>
              Date :<Title margin="0 0 0 5px">{dayjs(bookingDate.from).format("DD/MM/YYYY")}</Title>
            </Title>
            <Title style={{ whiteSpace: "nowrap" }} margin="5px 0 0 0" bold>
              {t("hour")} :{" "}
              <Title margin="0 0 0 5px">{dayjs(bookingDate.from).format("HH[h]mm")}</Title>
            </Title>
            <Text style={{ whiteSpace: "nowrap" }} grey margin="5px 0 0 0" bold>
              {t("duration")} :{" "}
              {`${dayjs(bookingDate.to).diff(dayjs(bookingDate.from), "hour").toString().padStart(2, "0")}h` +
                `${(dayjs(bookingDate.to).diff(dayjs(bookingDate.from), "minute") % 60).toString().padStart(2, "0")}`}
            </Text>
          </Column>
        )}

        {isResumeDisplay && activity.informations.cancelation && (
          <Text onClick={() => setIsCancellationPolicyModalOpen(true)} underline pointer>
            {t("cancellationPolicy")}
          </Text>
        )}
      </ContentContainer>
    </ActivityCardContainer>
  )
}

export default ActivityCard

const TextDescription = styled(Text)`
  white-space: pre-wrap;
  max-height: 150px;
  overflow: auto;
  align-items: flex-start;
  display: flex;
  white-space: pre-wrap;
`
