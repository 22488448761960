import axios from "axios";
import { z } from "zod";
import { dateableString, getAuthorizedConfig, numberablePositiveString } from "../../helpers/helpers";
import { ActivityAvailabilities } from "types/activity.type";
import { ActivityPricesStatusEnum, ActivityPricesTypeEnum, ActivityTypeEnum } from "helpers/constants";

export const activityIndividualBookingSettings = z.object({
  type: z.literal(ActivityTypeEnum.INDIVIDUAL),
  maxCapacity: z.number().min(1),
});

export const activityGroupBookingSettings = z.object({
  type: z.literal(ActivityTypeEnum.GROUP),
  maxUnitsPerGroup: z.number().min(1),
  maxGroups: z.number().min(1),
});

export const activityBookingSettings = z.union([
  activityIndividualBookingSettings,
  activityGroupBookingSettings,
]);

export type ActivityBookingSettings = z.infer<typeof activityBookingSettings>;

export const activityInformations = z.object({
  name: z.string(),
  category: z.string(),
  description: z.string(),
  images: z.array(
    z.object({
      url: z.string(),
      title: z.string().optional(),
      base64File: z.string().optional(),
      mimeType: z.string().optional(),
    })
  ),
  slotDuration: z.number().min(0).max(720),
  address: z.string(),
  phone: z.string(),
  email: z.string().email(),
  cancelation: z.string(),
  isEnabled: z.boolean(),
  bookingSettings: activityBookingSettings,
});

export type ActivityInformations = z.infer<typeof activityInformations>;


const slot = z.object({
    to: z.string().min(5),
    from: z.string().min(5),
  });
  
const day = z.object({
        slots: z.array(slot),
    });

export const activityHours = z.object({
  dates: z.object({
    from: dateableString,
    to: dateableString
  }).nullable().optional(),
  hours: z.object({
      "0": day,
      "1": day,
      "2": day,
      "3": day,
      "4": day,
      "5": day,
      "6": day,
  })
})

export type Slot = z.infer<typeof slot>;
export type Day = z.infer<typeof day>;
export type ActivityHours = z.infer<typeof activityHours>;

  
const activityPrice = z.object({
  id: z.number().optional(),
  name: z.string().min(5),
  price: z.number().min(1),
  description: z.string().nullable(),
  type: z.nativeEnum(ActivityPricesTypeEnum).nullable().optional(),
  status: z.nativeEnum(ActivityPricesStatusEnum),
});

export const activityPrices = z.object({
    prices: z.array(activityPrice)
})

export type ActivityPrices = z.infer<typeof activityPrices>;

const activityChannel = z.object({
  platformId: z.number(),
  name: z.string(),
  status: z.string(),
});


export const activityChannels = z.array(activityChannel);

export type ActivityChannels = z.infer<typeof activityChannels>;

const activity = z.object({
  id: z.number(),
  informations: activityInformations,
  hours: activityHours,
  prices: z.array(activityPrice),
  channels: z.array(activityChannel),
});
export type Activity = z.infer<typeof activity>;

const upsertActivityInput = z.object({
  body: z.object({
    activityId: numberablePositiveString.nullable(),
    informations: activityInformations.optional(),
    hours: activityHours.optional(),
    prices: z.array(activityPrice).optional(),
    channels: z.array(activityChannel).optional(),
  })
})

type UpsertActivityInput = z.infer<typeof upsertActivityInput>;

const availability = z.object({
  startTime: z.string(),
  endTime: z.string(),
  numberOfUnitBooked: z.number(),
  maxCapacity: z.number(),
});

export type Availability = z.infer<typeof availability>;

// get activities
export async function getActivities(clientId?: number | string): Promise<Activity[]> {
  const config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/client/${clientId}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}

// get activity
export async function getActivityById(activityId: number): Promise<Activity> {
  const config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/activity/${activityId}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}

// post/put activity
export async function upsertActivity(data: UpsertActivityInput['body']) {
  const config: any = getAuthorizedConfig('POST');
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/`
  config['data'] = data
  const response = await axios.request({
    ...config,         
    maxContentLength: 100000000,
    maxBodyLength: 1000000000
  })
  return response;
}

// getAvaibilities
export async function getAvaibilities(activityId: number, from: string, to: string): Promise<ActivityAvailabilities[]> {
  const config = getAuthorizedConfig('GET')
  config['url'] = `${process.env.REACT_APP_API_URL}/activities/availabilities/${activityId}?from=${from}&to=${to}`
  const response = await axios.request(config)
  const result = response.data;
  return result;
}
